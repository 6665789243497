.RPMLogin{
    background-color: #180e24;
    font-size: 2vh;
    font-weight: 500;
    font-family: 'BackToSchool';
    text-shadow: 
    0px 0px 0 black,  
    3px 0px 0 black,
    0px  3px 0 black,
    3px  3px 0 black;
    color: #fff;
    border-radius: 20px;
}

.rpm-button {
  position: relative;
  z-index: 500000;
  font-size: 2vh;
  font-weight: 500;
  font-family: 'BackToSchool';
  text-shadow: 
  0px 0px 0 black,  
  3px 0px 0 black,
  0px  3px 0 black,
  3px  3px 0 black;
  line-height: 1;
  border-radius: 30px;
  border-width: 2px solid transparent;
  padding: 10px 15px;
  min-height: 40px;
  transition: .2s;
  text-transform: capitalize;
  color: #fff;
  background-color: #2f90db;
  box-shadow: 0px 4px 0px 0px #2673AF;
  border: 1px solid #2f90db;
  border-bottom: 2px solid #ACD3F1;
}
.rpm-button:active{
  box-shadow: 0px 2px 0px 0px #2673AF;
  transform: translateY(2px)
}
.rpm-button:disabled {
  background-color: #A9A29D;
  box-shadow: 0px 4px 0px 0px #79716B;
  border: 1px solid #A9A29D;
  border-bottom: 2px solid #E7E5E4;
  cursor: not-allowed;
  color: #fff;
}

.rpm-button:disabled:hover {
  background-color: #98a3ac5e;
  cursor: not-allowed;
  color: #fff;
}
.rpm-button:hover{
  background-color: #a0c9e9;
}


.asset-button{
  font-size: 2vh;
  font-weight: 500;
  line-height: 1;
  border-radius: 20px;
  border-width: 2px solid transparent;
  padding: 5px 15px;
  margin: 5px;
  min-height: 40px;
  transition: .2s;
  text-transform: capitalize;
  color: #fff;
  padding: 10px;
  font-family: 'BackToSchool';
  text-shadow: 
  0px 0px 0 black,  
  3px 0px 0 black,
  0px  3px 0 black,
  3px  3px 0 black;
  letter-spacing: 2px;
  background: rgb(42,222,138);
  background: linear-gradient(13deg, rgba(42,222,138,1) 1%, rgba(82,199,226,0.7245273109243697) 64%);
  transition: all 0.1s ease-in-out; 
}

.asset-button:active{
  box-shadow: 0px 2px 0px 0px #656F34;
  transform: translateY(2px);
}
.asset-button:hover {
  background-color:  #d5e09c;
}

.rpm-wrapper{
  border-radius: 25px;
  background-color: rgba(34, 36, 48, 0.7);
  height: 60%;
}

.rpm-container {
  height: 90%;
  width: 98%;
  display: flex;
  flex-direction: row;
  margin: 10px auto auto auto;
  z-index: 7;
}

.rpm-container-modal{
  display: flex;
  flex-direction: row;
  position: absolute;
  height: 95%;
  top: -20px;
  width: 95%;
  margin: 0;
  z-index: 70;
  overflow: auto;
}

@media (max-width: 1300px) {
  .rpm-container {
    flex-direction: column;
    height: fit-content;
  }
  .asset-picker{
    margin-top: 10%;
  }
  .rpm-wrapper{
    height: 100%;
  }
  .rpm-container-modal{
    flex-direction: column;
    height: fit-content;
  }
  .asset-picker{
    height: 320px !important;
  }
  .profile-modal{
    height: 90%;
    width: 100%;
    background: none;
  }
  .visage{
    height: 300px !important;
    width: 100% !important;
    right: 20px !important;
  }
}

.loader-rpm {
    height: 5px;
    width: 5px;
    color: rgb(42, 222, 138);
    box-shadow: -10px -10px 0 5px,
                -10px -10px 0 5px,
                -10px -10px 0 5px,
                -10px -10px 0 5px;
    animation: loader-38 6s infinite;
  }

  @keyframes loader-38 {
    0% {
      box-shadow: -10px -10px 0 5px,
                  -10px -10px 0 5px,
                  -10px -10px 0 5px,
                  -10px -10px 0 5px;
    }
    8.33% {
      box-shadow: -10px -10px 0 5px,
                  10px -10px 0 5px,
                  10px -10px 0 5px,
                  10px -10px 0 5px;
    }
    16.66% {
      box-shadow: -10px -10px 0 5px,
                  10px -10px 0 5px,
                  10px 10px 0 5px,
                  10px 10px 0 5px;
    }
    24.99% {
      box-shadow: -10px -10px 0 5px,
                  10px -10px 0 5px,
                  10px 10px 0 5px,
                  -10px 10px 0 5px;
    }
    33.32% {
      box-shadow: -10px -10px 0 5px,
                  10px -10px 0 5px,
                  10px 10px 0 5px,
                  -10px -10px 0 5px;
    }
    41.65% {
      box-shadow: 10px -10px 0 5px,
                  10px -10px 0 5px,
                  10px 10px 0 5px,
                  10px -10px 0 5px;
    }
    49.98% {
      box-shadow: 10px 10px 0 5px,
                10px 10px 0 5px,
                10px 10px 0 5px,
                10px 10px 0 5px;
    }
    58.31% {
      box-shadow: -10px 10px 0 5px,
                  -10px 10px 0 5px,
                  10px 10px 0 5px,
                  -10px 10px 0 5px;
    }
    66.64% {
      box-shadow: -10px -10px 0 5px,
                  -10px -10px 0 5px,
                  10px 10px 0 5px,
                  -10px 10px 0 5px;
    }
    74.97% {
      box-shadow: -10px -10px 0 5px,
                  10px -10px 0 5px,
                  10px 10px 0 5px,
                  -10px 10px 0 5px;
    }
    83.3% {
      box-shadow: -10px -10px 0 5px,
                  10px 10px 0 5px,
                  10px 10px 0 5px,
                  -10px 10px 0 5px;
    }
    91.63% {
      box-shadow: -10px -10px 0 5px,
                  -10px 10px 0 5px,
                  -10px 10px 0 5px,
                  -10px 10px 0 5px;
    }
    100% {
      box-shadow: -10px -10px 0 5px,
                  -10px -10px 0 5px,
                  -10px -10px 0 5px,
                  -10px -10px 0 5px;
    }
  }


        
      
